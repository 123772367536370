#Accepted {
  background-color: #80FF80;
}

#AdminApplicationsBox {
  width: 1214px;
  margin: auto;
  padding-top: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: #FFFFFF;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

#AlertCover {
  position:fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 5;
  width: 100%;
  height: 100%;
}

#AlertScreen {
  max-width: 300px;
  text-align: center;
  z-index: 10;
  margin: auto;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 20px;
  font-size: 11px;
  font-family: Verdana, Helvetica, sans-serif;
}

#ApplicationRow {
  white-space: nowrap;
}

#ApplicationsBox {
  width: 964px;
}

#applicationsBoxWrapper {
  min-width: 1024px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#ApplicationsTable {
  max-width: 100%;
  min-width: 100%;
}

#Applied {
  background-color: #FFFFFF;
}

#Block {
  width: 1214px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  background: #FFFFFF;
}

#BlockTitle {
  width: -moz-calc(100% - 400px);
  width: -webkit-calc(100% - 400px);
  width: calc(100% - 400px);
  display: inline-block;
  text-align: center;
}

#Body {
  position: fixed;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 150px;
  margin-bottom: 10px;
  height: 30%;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
  width: -moz-calc(100% - 20px);
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  height: -moz-calc(100% - 160px);
  height: -webkit-calc(100% - 160px);
  height: calc(100% - 160px);
  min-width: 1024px;
}

#ButtonWidth {
  min-width: 80px;
  max-width: 80px;
}

#ButtonWithDate {
  text-align: center;
  font-size: 10px;
  white-space: nowrap;
  width: 75px;
}

#Center {
  text-align: center;
}

#Comment {
  max-height: 200px;
  min-height: 200px;
  max-width: 190px;
  min-width: 190px;
}

#Container {
  height: 100%;
}

#DataArea {
  display: inline-block;
  width: -moz-calc(100% - 160px);
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
  min-width: 800px;
  height: -moz-calc(100% - 9px);
  height: -webkit-calc(100% - 9px);
  height: calc(100% - 9px);
  background-color: white;
  border-style: ridge;
  overflow-y: auto;
  margin-left: 15px;
}

#EditTableNormalField {
  text-decoration: none;
  font-weight: normal;
  text-align: right;
}

#EditTableRequiredField {
  text-decoration: none;
  font-weight: bold;
  text-align: right;
}

#EditTableValue {
  font-weight: normal;
  text-align: left;
}

#FieTooltip {
  color: red;
  text-align: left;
}

#Footer {
  background-image: url('./img/footer.gif');
  position: fixed;
  bottom: 0;
  height: 20px;
  width: 100%;
  min-width: 1034px;
}

#HalfWidth {
  width: 50%;
}

#Header {
  background-image: url('./img/header.gif');
  position: fixed;
  top: 0;
  height: 170px;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  font-family: Verdana, Helvetica, sans-serif;
  min-width: 1034px;
}

#Heading {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: Verdana, Helvetica, sans-serif;
}

#Longest {
  max-width: 300px;
  overflow: hidden;
}

#LongWidth {
  max-width: 150px;
  overflow: hidden;
}

#MediumWidth {
  max-width: 100px;
  overflow: hidden;
}

#Nullified {
  background-color: #CCCC00;
}

#Page {
  min-width: 500px;
  height: 100%;
  background-color: black;
}

#Progress{
  background-color: green;
  height: 20px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

#ProgressBar{
  background-color: #FFFFFF;
  height: 20px;
  width: 300px;
  margin: auto;
  border-radius: 7px;
}

#Search {
  background-color: white;
  width: 100%;
  border-style: ridge;
}

#SearchIcon {
  display: inline-block;
  vertical-align: bottom;
  text-align: right;
  width: 30px;
}

#SearchInput {
  display: inline-block;
  margin: 5px;
}

#SearchList {
  border-style: ridge;
  background-color: white;
  width: 100%;
  height: -moz-calc(100% - 55px);
  height: -webkit-calc(100% - 55px);
  height: calc(100% - 55px);
  margin-top: 10px;
  overflow-y: auto;
}

#SelectedTab {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 20px;
  background-color: #FFFFFF;
  display: inline-block;
  width: 246px;
  border: 2px solid #FFFFFF;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
}

#Sidebar {
  display: inline-block;
  width: 130px;
  height: 100%;
}

#SpaceHolder {
  width: 100px;
  height: 5px;
  background-color: green;
  display: inline-block;
}

#ReportsText {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  display: inline-block;
}

#TableButton {
  text-align: center;
  font-size: 10px;
  white-space: nowrap;
}

#Tabs {
  margin: auto;
  width: 1254px;
  text-align: center;
  font-weight: bold;
}

#Title {
  text-align: center;
  margin: auto;
  font-weight: bold;
  padding: 10px;
}

#Unfinished {
  background-color: #A0A0C0;
}

#Upload {
  text-align: center;
}

#UploadBox {
  position: relative;
  width: 350px;
  display: inline-block;
  margin-left: 5px;
}

#UploadButton {
  text-align: center;
  width: 100px;
  padding: 2px;
  margin-left: 5px;
  margin-right: 5px;
}

#UploadCover {
  position: absolute;
  width: 350px;
  top: 0px;
  left: 0px;
  padding: 0;
  margin: 0;
  z-index: 1;
}

#UploadInput {
  z-index: 2;
  position: relative;
  opacity: 0;
  width: 350px;
  height: 100px;
  padding-left: 20px;
}

#Version {
  font-size: 13px;
  text-align: center;
  padding-top: 20px;
}

.ApplicationTable {
  border-collapse: collapse;
}

.ApplicationTable td {
  vertical-align: top;
  padding-top: 5px;
  padding-bottom: 5px;
}

.backgroundLightBlue {

  background-color: lightblue;
  border: 1px solid black;
  border-radius: 3px;

}

.backgroundWhite {
  background-color: white;
}

.backgroundYellow {

  background-color: yellow;
  border: 1px solid black;
  border-radius: 3px;

}

.ButtonLikeLink {
  font: inherit;
  cursor: pointer;
  word-break: break-all;
  word-break: break-word;

}

.center {
  text-align: center;
}

.commonText {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  color: #000000;
}

.Center {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.Column {
  width: 50%;
  display: inline-block;
  text-align: center;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
}

.Content {
  text-align: left;
  width: 50%;
}

.CurrentTable {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}

.CurrentTable td {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  border: 1px solid #5F707F;
  border-collapse: collapse;
  word-break: break-all;
  word-break: break-word;
}

.CurrentTable th {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  border-collapse: collapse;
  border: 1px solid #5F707F;
}

.EditApplication {
  margin-top: 20px;
  background-color: #EEE8E0;
  border: 1px solid #5F707F;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
  width: 100%;
  min-width: 600px;
}

.EditTable {
  width: 100%;
  border: 1px solid #5F707F;
  background-color: #eeeeee;
}

.EditTable td {
  border-top: none;
  border-bottom: 1px solid #DAD8D8;
  border-left: none;
  border-right: none;
  border-collapse: collapse;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  width: 50%;
}

.EditTable tr {
  height: 25px;
  border-collapse: collapse
}

.EditTableNormalField {
  text-decoration: none;
  font-weight: normal;
  text-align: right;
}

.EditTableRequiredField {
  text-decoration: none;
  font-weight: bold;
  text-align: right;
}

.EditTableValue {
  font-weight: normal;
  text-align: left;
}

.EditTableValue input {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
}

.EditTableValue textarea {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
}

.FitImage {
  width: 22px;
  height: auto;
  position: absolute;
  left: 0px;
  top: -10px;
}

.Header {
  border-top: 1px solid #B8B8B8;
  border-bottom: 1px solid #B8B8B8;
}

.HeadingCell {
  text-align: center;
  min-width: 300px;
  max-width: 300px;
}

.HideOverflow {
  position: relative;
  cursor: pointer;
}

.inlineBlock {
  display: inline-block;
}

.Inline {
  display: inline-block;
  padding: 5px;
}

.InnerBox {
  background-color: #DCDCDC;
  padding: 10px;
  border: 1px solid #B8B8B8;
}

.largeBoxWrapper {
  min-width: 1074px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Label {
  text-align: right;
  font-weight: bold;
  width: 50%;
}

.left {
  text-align: left;
}

.linkLikeText {
  text-decoration: underline;
  color: inherit;
}

.loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
}

.Logo {
  width: 155px;
  height: 80px;
  text-align: center;
}

.Lower {
  vertical-align: -2px;
}

.maxHeight {
  height: 100%;
}

.maxWidth {
  min-width: 1020px;
}

.message {
  padding-bottom: 20px;
  word-wrap: break-word;
  width: 400px;
}

.messageCover{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  width: 100%;
  height: 100%;
}

.messageScreen {
  width: 400px;
  z-index: 12;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #bbb;
  background: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.marginAuto {
  margin: auto;
}

.MaxWidth {
  width: 100%;
}

.Medium {
  width: 210px;
}

.MediumField {
  max-width: 150px;
  min-width: 150px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
}

.Middle {
  vertical-align: middle;
}

.moveLower {
  position: relative;
  bottom: -10px
}

.NormalField {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  text-align: right;
}

.NormalLabel {
  text-align: right;
  width: 50%;
}

.OuterBox {
  margin: auto;
  border-radius: 20px;
  background: #FFFFFF;
  padding: 20px;
}

.QuarterWidth {
  width: 25%;
}

.redText {
  color: red;
  margin-left: 5px;
}

.RequiredField {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  text-align: right;
  width: 50%;
}

.ReportsButton {
  text-align: center;
  padding: 2px;
  margin: 5px;
  width: 55px;
}

.ResizeImage {
  width: 22px;
  height: auto;
  cursor: pointer;
}

.SearchButton {
  text-align: left;
  width: 100%;
  background: none;
  border: none; 
  padding: 0;
  font: inherit;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  white-space: normal;
  word-break: break-all;
  word-break: break-word;

}

.SearchLine {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
  word-break: break-all;
  word-break: break-word;
}

.SectionName {
  text-align: left;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 11px;
}

.mediumBox {
  width: 600px;
}

.mediumBoxWrapper {
  min-width: 660px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding5px {
  padding: 5px;
}

.smallBox {
  width: 350px;
}

.smallBoxWrapper {
  min-width: 410px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.SmallWidth {
  width: 15%;
  white-space: nowrap;
}

.Space {
  margin-left: 20px;
}

.StandardButton {
  text-align: center;
  padding: 2px;
  margin: 5px;
}

.Subtitle {
  background-color: #B8B8B8;
  padding: 2px;
}

.textCenter {
  text-align: center;
}

.TextLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.TopButtons {
  min-width: 155px;
  max-width: 155px;
  height: 80px;
  text-align: center;
}

.unselectedTab {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 20px;
  padding-bottom: 13px;
  background-color: #DCDCDC;
  display: inline-block;
  width: 249px;
  border: 1px solid #B8B8B8;
  cursor: pointer;
  font: inherit;
  box-shadow: none;
  outline: none;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
}

.warning {
  color: red;
  background-color: white;
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
  z-index: 25;
}

.Width10 {
  min-width: 10px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.Width30 {
  min-width: 30px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.Width40 {
  min-width: 40px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.width75percent {
  max-width: 75%;
  min-width: 75%;
}

.Width60 {
  min-width: 60px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.Width70 {
  min-width: 75px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.Width80 {
  padding: 3px;
  min-width: 80px;
  text-align: center;
  word-break: break-all;
  word-break: break-word;
}

.Width100 {
  padding: 3px;
  min-width: 100px;
  word-break: break-all;
  word-break: break-word;
}

.Width120 {
  padding: 3px;
  min-width: 120px;
  word-break: break-all;
  word-break: break-word;
}

.Width150 {
  padding: 3px;
  min-width: 130px;
  word-break: break-all;
  word-break: break-word;
}

.Width170 {
  padding: 3px;
  min-width: 170px;
  word-break: break-all;
  word-break: break-word;
}

.Width200 {
  min-width: 200px;
  word-break: break-all;
  word-break: break-word;
}

.Width250 {
  padding: 3px;
  min-width: 250px;
  word-break: break-all;
  word-break: break-word;
}

.Width525 {
  padding: 3px;
  min-width: 525px;
  max-width: 525px;
  word-break: break-all;
  word-break: break-word;
}

.WidthMax {
  width: 100%;
  word-break: break-all;
  word-break: break-word;
  text-align: center;
}

.WidthRemaining {
  padding: 3px;
  width: 100%;
  word-break: break-all;
  word-break: break-word;
}

body {
  background-color: #E7D074;
  margin: 0px;
}

tr.Space > td {
  padding-bottom: 1em;
}

td, ul {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
}

li {
  line-height: 27px;
}

tr {
  page-break-inside: avoid;
}
    

input, button, select {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
}

h2 {
  font-family: Verdana, Helvetica, sans-serif;
}

th {
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
}

button.SearchButton:hover {
  background-color: #aabbcc;
}